import React from 'react'

import './HighlightBadge.scss'

const HighlightBadge = props => {
  return (
    <div className="HighlightBadge">
      RECOMENDADO
    </div>
  )
}

export default HighlightBadge
